import React from 'react';
import style from './DeleteBtn.module.scss'
import classNames from 'classnames';

export const DeleteBtn = ({
	type,
	handle = () => {},
	value,
}) => {
	const handleClick = () => {
		if (type !== 'head') {
			handle(value)
		}
	}
	return (
		<svg
			version='1.1'
			viewBox='0 0 256 256'
			className={classNames(style.del, {
				[style.del__head]: type === 'head',
			})}
			onClick={handleClick}
		>
			<g
				className={style.del__G}
				transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
			>
				<path
					d='M 68.842 90 H 21.158 c -4.251 0 -7.696 -3.446 -7.696 -7.696 v -52.09 h 63.077 v 52.09 C 76.538 86.554 73.092 90 68.842 90 z'
					className={classNames(style.del__P1, {
						[style.del__P1__head]: type === 'head',
					})}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
				<path
					d='M 78.321 22.213 H 11.679 c -2.209 0 -4 -1.791 -4 -4 s 1.791 -4 4 -4 h 66.643 c 2.209 0 4 1.791 4 4 S 80.53 22.213 78.321 22.213 z'
					className={classNames(style.del__P1, {
						[style.del__P1__head]: type === 'head',
					})}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
				<path
					d='M 57.815 22.213 h -25.63 c -2.209 0 -4 -1.791 -4 -4 V 7.696 C 28.185 3.453 31.637 0 35.881 0 h 18.238 c 4.244 0 7.696 3.453 7.696 7.696 v 10.517 C 61.815 20.422 60.024 22.213 57.815 22.213 z M 36.185 14.213 h 17.63 V 8 h -17.63 V 14.213 z'
					className={classNames(style.del__P1, {
						[style.del__P1__head]: type === 'head',
					})}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
				<path
					d='M 54.784 78.235 c -2.209 0 -4 -1.791 -4 -4 V 44.976 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 29.259 C 58.784 76.444 56.993 78.235 54.784 78.235 z'
					className={classNames(style.del__P2, {
						[style.del__P2__head]: type === 'head',
					})}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
				<path
					d='M 35.216 78.235 c -2.209 0 -4 -1.791 -4 -4 V 44.976 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 29.259 C 39.216 76.444 37.425 78.235 35.216 78.235 z'
					className={classNames(style.del__P2, {
						[style.del__P2__head]: type === 'head',
					})}
					transform=' matrix(1 0 0 1 0 0) '
					strokeLinecap='round'
				/>
			</g>
		</svg>
	)
}
