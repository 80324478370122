import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import './index.scss';

const rootElement = document.getElementById('root')
if (!rootElement) {
	throw new Error(
		"Root element not found. Make sure your index.html has <div id='root'></div>."
	)
}
const root = ReactDOM.createRoot(rootElement)
root.render(
	<React.StrictMode>
		<BrowserRouter
			future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
		>
			<App />
		</BrowserRouter>
	</React.StrictMode>
)
