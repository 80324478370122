import React, { startTransition, useContext, useEffect, useState } from 'react'
import style from './CartOption.module.scss'
import { getTranslation, Language } from '../../language'
import axios from 'axios'
import { Btn } from '../../c_ui/Btn'
import { DeleteBtn } from '../../c_ui/DeleteBtn'
import { Input } from '../../c_ui/Input/Input'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../Loader'
import { CustomAlert } from '../CustomAlert'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const CartOption = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [resources, setResources] = useState([])
	const [resourceName, setResourceName] = useState('')
	const [isCollected, setIsCollected] = useState(false)
	const [date, setDate] = useState('')
	const [isLoader, setIsLoader] = useState(false)
	const [alertText, setAlertText] = useState('')

	const fetchResources = async () => {
		try {
			const dataResources = await axios.post(`${BASE_URL}/getResources`)

			setResources(dataResources.data)
		} catch (error) {
			console.log('fetchResources func error', error)
		}
	}

	useEffect(() => {
		fetchResources()

		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}
	}, [])

	const handleAddResource = async event => {
		event.preventDefault()

		if (!resourceName.length) {
			setAlertText(TEXT.resource_is_required)
			return
		} else {
			setIsLoader(true)

			if (resourceName.trim()) {
				try {
					await axios.post(`${BASE_URL}/addResource/`, {
						resourceName: resourceName.trim(),
					})

					setResourceName('')
					await fetchResources()
				} catch (error) {
					console.log('addResource error', error)
				} finally {
					setIsLoader(false)
				}
			}
		}
	}

	const handleDeleteResource = async deletedResource => {
		setIsLoader(true)

		try {
			await axios.post(`${BASE_URL}/deleteResource`, {
				resourceName: deletedResource,
			})

			await fetchResources()
		} catch (error) {
			console.log('deleteResource error', error)
		} finally {
			setIsLoader(false)
		}
	}

	const handleCollectData = async event => {
		event.preventDefault()

		if (!date.length) {
			setAlertText(TEXT.date_is_required)
			return
		} else {
			const selectedDate = new Date(date).setHours(0, 0, 0, 0)
			const today = new Date().setHours(0, 0, 0, 0)
			const diffDays = (selectedDate - today) / (1000 * 60 * 60 * 24)

			const formData = {
				day: diffDays,
			}

			try {
				const request = await axios.post(`${BASE_URL}/collectData`, formData)

				if (request.status === 200) {
					setIsCollected(true)
				}
			} catch (error) {
				console.error('Помилка:', error)
			}
		}
	}

	return (
		<div className={style.cart}>
			<table>
				<thead>
					<tr>
						<th className={style.th}>{TEXT.researcher_tg_table_name}</th>
						<th className={style.th}>
							<DeleteBtn type={'head'} />
						</th>
					</tr>
				</thead>
				<tbody>
					{resources.length > 0 &&
						resources.map((resource, resourceIndex) => (
							<tr key={resourceIndex}>
								<td className={style.td}>{resource.name}</td>
								<td className={style.td__2}>
									<DeleteBtn
										handle={handleDeleteResource}
										value={resource.name}
									/>
								</td>
							</tr>
						))}
				</tbody>
			</table>

			<form onSubmit={handleAddResource} className={style.add}>
				<Input
					inputType={'text'}
					inputValue={resourceName}
					inputPlaceholder={TEXT.researcher_tg_new_resource_placeholder}
					setInputValue={setResourceName}
				/>

				<Btn type='submit' text={TEXT.researcher_tg_btn_add} />
			</form>

			<span className={style.collect}>
				{!isCollected ? (
					<form onSubmit={handleCollectData} className={style.collect__wrap}>
						<span className={style.collect__wrap2}>
							<p className={style.text}>{TEXT.researcher_add_date}</p>

							<Input
								inputType={'date'}
								inputValue={date}
								setInputValue={setDate}
							/>
						</span>

						<Btn type='submit' text={TEXT.researcher_tg_btn_collect} />
					</form>
				) : (
					<p className={style.collect__text}>{TEXT.researcher_tg_collecting}</p>
				)}
			</span>

			{isLoader && <Loader />}
			{alertText.length > 0 && (
				<CustomAlert message={alertText} onClose={setAlertText} />
			)}
		</div>
	)
}
