import React, { startTransition, useContext, useEffect, useState } from 'react'
import style from './CheckOption.module.scss'
import { getTranslation, Language } from '../../language'
import axios from 'axios'
import { Input } from '../../c_ui/Input/Input'
import { ScrollUpBtn } from '../../c_ui/ScrollUpBtn/ScrollUpBtn'
import { UpDownBtn } from '../../c_ui/UpDownBtn'
import { DoneIcon } from '../../c_ui/DoneIcon'
import { ResearcherModal } from '../ResearcherModal'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import { useNavigate } from 'react-router-dom'
import { Btn } from '../../c_ui/Btn'
import { Loader } from '../../components/Loader'
import { CustomAlert } from '../../components/CustomAlert'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const CheckOption = ({ userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [filesData, setFilesData] = useState([])
	const [modalInfo, setModalInfo] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [isLoader, setIsLoader] = useState(false)
	const [alertText, setAlertText] = useState('')

	const headers = [
		TEXT.researcher_check_table_date,
		TEXT.researcher_check_table_name,
		TEXT.researcher_check_table_resource,
		TEXT.researcher_check_table_size,
		<UpDownBtn type={'head'} />,
		TEXT.researcher_check_table_comment,
	]

	useEffect(() => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}
	}, [startDate, endDate, modalInfo])

	const handleManualDownload = data => {
		setModalInfo(data)
		document.body.style.overflow = 'hidden'
	}

	const checkFiles = async event => {
		event.preventDefault()

		if (!startDate.length || !endDate.length) {
			setAlertText(TEXT.both_dates_are_required)
			return
		} else {
			setIsLoader(true)

			const start = new Date(startDate)
			const end = new Date(endDate)
			const result = []
			const formatDate = date => {
				const year = date.getFullYear()
				const month = String(date.getMonth() + 1).padStart(2, '0')
				const day = String(date.getDate()).padStart(2, '0')
				return `${year}_${month}_${day}`
			}

			let currentDate = start
			while (currentDate <= end) {
				result.push(formatDate(currentDate))
				currentDate.setDate(currentDate.getDate() + 1)
			}

			// All Upload User Files and Unupload Files
			let allFiles = []

			// Unupload Files
			try {
				const unuploadFiles = await axios.post(`${BASE_URL}/getUnuploadFiles`, {
					targetDate: result,
				})

				if (unuploadFiles) {
					allFiles = [...allFiles, ...unuploadFiles.data]
				}
			} catch (error) {
				if (error.status === 404) {
					console.log('unuploadFiles funcs error', error)
				}
			}

			// All User Files
			try {
				const files = await axios.post(`${BASE_URL}/getAllUploadUserFiles`, {
					targetDate: result,
					currentUser: userName,
				})

				if (files) {
					allFiles = [
						...allFiles,
						...files.data.filter(file => file.is_uploaded),
					]
				}
			} catch (error) {
				if (error.status === 404) {
					console.log('files funcs error', error)
				}
			}

			if (errorMessage && allFiles.length) {
				setErrorMessage('')
			}

			if (!allFiles.length) {
				setErrorMessage('В обраний період не було завантажень')
			}

			setFilesData(allFiles)
			setIsLoader(false)
		}
	}

	return (
		<div className={style.check}>
			<p className={style.text}>{TEXT.researcher_check_date}</p>
			<form onSubmit={checkFiles} className={style.row}>
				<span className={style.row__part}>
					<p className={style.row__text}>{TEXT.from}</p>
					<Input
						inputType={'date'}
						inputValue={startDate}
						setInputValue={setStartDate}
						maxValue={endDate}
					/>
				</span>

				<span className={style.row__part}>
					<p className={style.row__text}>{TEXT.to}</p>
					<Input
						inputType={'date'}
						inputValue={endDate}
						setInputValue={setEndDate}
						minValue={startDate}
					/>
				</span>

				<Btn type='submit' text={TEXT.check} />
			</form>

			<table>
				<thead>
					<tr>
						{headers.map((header, headerIndex) => (
							<th key={headerIndex} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>

				<tbody>
					{filesData.map((fileData, fileDataIndex) => (
						<tr key={fileDataIndex}>
							{/* Date */}
							{fileData.post_date ? (
								<td title={fileData.post_date} className={style.td}>
									{fileData.post_date}
								</td>
							) : (
								<td className={style.td}></td>
							)}

							{/* Name */}
							{fileData.file_name ? (
								<td title={fileData.file_name} className={style.td}>
									{fileData.file_name}
								</td>
							) : (
								<td className={style.td}></td>
							)}

							{/* Resource */}
							{fileData.resource ? (
								<td title={fileData.resource} className={style.td}>
									{fileData.resource}
								</td>
							) : (
								<td className={style.td}></td>
							)}

							{/* Size */}
							{fileData.file_size ? (
								<td title={fileData.file_size} className={style.td}>
									{fileData.file_size}
								</td>
							) : (
								<td className={style.td}></td>
							)}

							{/* UpDownBtn */}
							{fileData.is_uploaded ? (
								<td title={userName} className={style.td}>
									<DoneIcon />
								</td>
							) : (
								<td title={userName} className={style.td}>
									<UpDownBtn
										type={'researcher'}
										data={fileData}
										handle={() => handleManualDownload(fileData)}
									/>
								</td>
							)}

							{/* Researcher comment */}
							{fileData.researcher_comment ? (
								<td title={fileData.researcher_comment} className={style.td}>
									{fileData.researcher_comment}
								</td>
							) : (
								<td className={style.td}></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			<p className={style.errorText}>{errorMessage}</p>

			{filesData.length > 20 && <ScrollUpBtn />}

			{modalInfo && (
				<ResearcherModal
					data={modalInfo}
					exitHandle={setModalInfo}
					userName={userName}
				/>
			)}

			{isLoader && <Loader />}
			{alertText.length > 0 && (
				<CustomAlert message={alertText} onClose={setAlertText} />
			)}
		</div>
	)
}
