import React, { startTransition, useContext, useEffect, useState } from 'react'
import style from './Inspector.module.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Wellcome } from '../../components/Wellcome'
import { Btn } from '../../c_ui/Btn'
import { getTranslation, Language } from '../../language'
import { AddAndDeleteOption } from '../../components/AddAndDeleteOption'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import { CartOption } from '../../components/CartOption'
import { TgCart } from '../../c_ui/TgCart'
import { FilesOption } from '../../components/FilesOption'

const BASE_URL = process.env.REACT_APP_BASE_URL

const roles = {
	ad: 'ad',
	files: 'files',
	tg: 'tg',
}

export const Inspector = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [userName, setUserName] = useState('')
	const [isActive, setIsActive] = useState('')

	useEffect(() => {
		async function fetchData() {
			const storedUserId = sessionStorage.getItem('id')

			try {
				const user = await axios.post(`${BASE_URL}/getUserById`, {
					userId: storedUserId,
				})

				if (user) {
					setUserName(user.data.name)
				}
			} catch (err) {
				console.log('fetchData func error')
				startTransition(() => {
					navigate('/')
				})
			}
		}

		fetchData()
	}, [])

	const handlerAddAndDelete = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}

		setIsActive(roles.ad)
	}

	const handlerFiles = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}

		setIsActive(roles.files)
	}

	const handlerCart = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}

		setIsActive(roles.tg)
	}

	return (
		<div className={style.inspector}>
			<Wellcome userName={userName} />

			<div className={style.buttons}>
				<Btn
					text={'+ / -'}
					handle={handlerAddAndDelete}
					isActive={isActive === roles.ad}
				/>
				<Btn
					text={TEXT.inspector_btn_files}
					handle={handlerFiles}
					isActive={isActive === roles.files}
				/>
				<Btn
					text={<TgCart />}
					handle={handlerCart}
					isActive={isActive === roles.tg}
				/>
			</div>

			{isActive === roles.ad && <AddAndDeleteOption userName={userName} />}
			{isActive === roles.files && <FilesOption />}
			{isActive === roles.tg && <CartOption />}

			{!isActive && <p className={style.choice}>{TEXT.choice}</p>}
		</div>
	)
}
