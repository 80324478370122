import React from 'react'
import style from './UpDownBtn.module.scss'
import classNames from 'classnames'

export const UpDownBtn = ({ type, data = {}, handle = () => {} }) => {
	const handleClick = () => {
		if (data.is_uploaded || type === 'researcher' || data.length) {
			handle()
		}
	}

	return (
		<button type='button' className={style.btn} onClick={handleClick}>
			<svg
				version='1.1'
				viewBox='0 0 256 256'
				className={classNames({
					[style.upDown]: true,
					[style.upDown__head]: type === 'head',
					[style.upDown__notUploaded]:
						data.is_uploaded === false && type === 'processor',
				})}
			>
				<g
					className={style.upDown__G}
					transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
				>
					<path
						d='M 71.361 23.522 C 69.67 11.555 59.257 2.246 46.916 2.246 h -3.832 c -12.341 0 -22.753 9.309 -24.444 21.276 C 8.234 24.276 0 32.983 0 43.577 c 0 11.087 9.02 20.108 20.107 20.108 h 14.473 c 4.091 0 7.419 3.328 7.419 7.419 v 6.408 l -3.268 -3.268 c -1.171 -1.172 -3.071 -1.172 -4.242 0 c -1.172 1.171 -1.172 3.071 0 4.242 l 8.386 8.386 c 0.14 0.141 0.295 0.266 0.461 0.377 c 0.071 0.047 0.148 0.08 0.222 0.121 c 0.097 0.054 0.191 0.112 0.294 0.155 c 0.098 0.04 0.199 0.063 0.3 0.093 c 0.087 0.026 0.17 0.059 0.26 0.077 c 0.194 0.039 0.39 0.059 0.587 0.059 c 0.197 0 0.393 -0.021 0.587 -0.059 c 0.092 -0.018 0.178 -0.053 0.267 -0.079 c 0.098 -0.029 0.197 -0.051 0.292 -0.091 c 0.108 -0.045 0.206 -0.105 0.307 -0.162 c 0.069 -0.039 0.142 -0.069 0.208 -0.113 c 0.167 -0.111 0.322 -0.237 0.463 -0.379 l 8.385 -8.385 c 1.172 -1.171 1.172 -3.071 0 -4.242 c -1.172 -1.172 -3.07 -1.172 -4.242 0 L 48 77.512 v -6.408 c 0 -7.399 -6.02 -13.419 -13.419 -13.419 H 20.107 C 12.329 57.685 6 51.355 6 43.577 s 6.329 -14.107 14.107 -14.107 h 1.297 c 1.631 0 2.963 -1.303 3 -2.934 c 0.221 -10.084 8.601 -18.289 18.68 -18.289 h 3.832 c 10.078 0 18.459 8.205 18.681 18.289 c 0.035 1.631 1.368 2.934 2.999 2.934 h 1.297 C 77.672 29.469 84 35.798 84 43.577 s -6.328 14.108 -14.107 14.108 H 55.419 c -4.091 0 -7.419 -3.328 -7.419 -7.42 v -5.329 l 3.268 3.268 c 0.586 0.586 1.354 0.879 2.121 0.879 s 1.535 -0.293 2.121 -0.879 c 1.172 -1.171 1.172 -3.071 0 -4.242 l -8.386 -8.386 C 46.581 35.03 45.83 34.693 45 34.693 c -0.827 0 -1.576 0.335 -2.118 0.876 c -0.001 0.001 -0.002 0.002 -0.003 0.003 l -8.389 8.389 c -1.171 1.171 -1.171 3.071 0 4.242 c 1.171 1.172 3.071 1.172 4.243 0 L 42 44.936 v 5.329 c 0 7.399 6.02 13.42 13.419 13.42 h 14.474 C 80.979 63.685 90 54.664 90 43.577 C 90 32.983 81.765 24.277 71.361 23.522 z'
						className={classNames({
							[style.upDown__P]: true,
							[style.upDown__P__head]: type === 'head',
							[style.upDown__P__notUploaded]:
								data.is_uploaded === false && type === 'processor',
							[style.upDown__P__notDownloaded]:
								data.is_uploaded === false && type === 'researcher',
							[style.upDown__P__isDownloaded]:
								data.is_processor_take && type === 'processor',
						})}
						transform=' matrix(1 0 0 1 0 0) '
						strokeLinecap='round'
					/>
				</g>
			</svg>
		</button>
	)
}
