import React, { startTransition, useContext, useState } from 'react'
import { getTranslation, Language } from '../../language'
import axios from 'axios'
import style from './Main.module.scss'
import { useNavigate } from 'react-router-dom'
import { Btn } from '../../c_ui/Btn'
import { Input } from '../../c_ui/Input/Input'

const BASE_URL = process.env.REACT_APP_BASE_URL

const roles = {
	researcher: 'researcher',
	processor: 'processor',
	inspector: 'inspector',
}

export const Main = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [userPosition, setUserPosition] = useState('')
	const [userLogin, setUserLogin] = useState('')
	const [userPassword, setUserPassword] = useState('')
	const [accessError, setAccessError] = useState(false)

	const handlerPosition = event => {
		setUserPosition(event.target.value)
		if (accessError) {
			setAccessError(false)
		}
	}

	const handleKeyDown = event => {
		if (event.key === 'Enter' && userLogin && userPassword) {
			handlerCheck()
		}
	}

	const handlerCheck = async () => {
		try {
			const response = await axios.post(`${BASE_URL}/getUserRank`, {
				name: userLogin,
				password: userPassword,
				role: userPosition,
			})

			if (response.status === 200 && response.data.role === userPosition) {
				if (sessionStorage.length) {
					sessionStorage.clear()
				}

				sessionStorage.setItem('id', response.data._id)
				sessionStorage.setItem('time', new Date().getTime())
				sessionStorage.setItem('role', userPosition)

				setTimeout(() => {
					sessionStorage.clear()
				}, 3600000 * 6)

				startTransition(() => {
					navigate(response.data.role)
				})
			}

			if (response.status === 200 && response.data.role !== userPosition) {
				setAccessError(true)
			}
		} catch (err) {
			setAccessError(true)
		}
	}

	return (
		<div className={style.main}>
			<select
				value={userPosition}
				onChange={handlerPosition}
				className={style.select}
			>
				<option value='' disabled>
					{TEXT.who_are_you}
				</option>

				<option value={roles.researcher}>{TEXT.researcher}</option>

				<option value={roles.processor}>{TEXT.processor}</option>

				<option value={roles.inspector}>{TEXT.inspector}</option>
			</select>

			{userPosition && (
				<div className={style.inputs}>
					{accessError && <p className={style.errorText}>{TEXT.error_data}</p>}

					<p className={style.text}>{TEXT.lets_check}</p>

					<Input
						inputType={'text'}
						inputPlaceholder={TEXT.login}
						inputValue={userLogin}
						setInputValue={setUserLogin}
						optionalHadle={handleKeyDown}
					/>

					<Input
						inputType={'password'}
						inputPlaceholder={TEXT.password}
						inputValue={userPassword}
						setInputValue={setUserPassword}
						optionalHadle={handleKeyDown}
					/>

					<span className={style.btn}>
						{userLogin && userPassword && (
							<Btn text={TEXT.check} handle={handlerCheck} />
						)}
					</span>
				</div>
			)}
		</div>
	)
}
