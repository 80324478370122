import React, { startTransition, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import { Wellcome } from '../../components/Wellcome'
import { ProcessorModal } from '../../components/ProcessorModal'
import { Input } from '../../c_ui/Input/Input'
import { UpDownBtn } from '../../c_ui/UpDownBtn'
import { DoneIcon } from '../../c_ui/DoneIcon'
import { IgnoreIcon } from '../../c_ui/IgnoreIcon'
import style from './Processor.module.scss'
import { ScrollUpBtn } from '../../c_ui/ScrollUpBtn/ScrollUpBtn'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import { Btn } from '../../c_ui/Btn'
import { Loader } from '../../components/Loader'
import { CustomAlert } from '../../components/CustomAlert'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const Processor = () => {
	const navigate = useNavigate()
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [userName, setUserName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [filesData, setFilesData] = useState([])
	const [modal, setModal] = useState(false)
	const [fileData, setFileData] = useState(null)
	const [isLoader, setIsLoader] = useState(false)
	const [alertText, setAlertText] = useState('')

	const [errorMessage, setErrorMessage] = useState('')

	const headers = [
		TEXT.processor_table_date,
		TEXT.processor_table_name,
		TEXT.processor_table_resource,
		<UpDownBtn type={'head'} />,
		TEXT.processor_table_researcher,
		TEXT.processor_table_size,
		TEXT.processor_table_comment,
	]

	useEffect(() => {
		async function fetchData() {
			const storedUserId = sessionStorage.getItem('id')

			try {
				const user = await axios.post(`${BASE_URL}/getUserById`, {
					userId: storedUserId,
				})

				if (user) {
					setUserName(user.data.name)
				}
			} catch (err) {
				console.log('fetchData func error')
				startTransition(() => {
					navigate('/')
				})
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}
	}, [startDate, endDate, modal])

	const handleUpDownload = data => {
		document.body.style.overflow = 'hidden'
		setFileData(data)
		setModal(true)
	}

	const checkFiles = async event => {
		if (event) {
			event.preventDefault()
		}

		if (!startDate.length || !endDate.length) {
			setAlertText(TEXT.both_dates_are_required)
			return
		} else {
			setIsLoader(true)

			const start = new Date(startDate)
			const end = new Date(endDate)
			const result = []
			const formatDate = date => {
				const year = date.getFullYear()
				const month = String(date.getMonth() + 1).padStart(2, '0')
				const day = String(date.getDate()).padStart(2, '0')
				return `${year}_${month}_${day}`
			}

			let currentDate = start
			while (currentDate <= end) {
				result.push(formatDate(currentDate))
				currentDate.setDate(currentDate.getDate() + 1)
			}

			try {
				const files = await axios.post(`${BASE_URL}/getAllUploadFiles`, {
					targetDate: result,
				})

				if (files) {
					const noHamsterFiles = files.data.filter(
						file => file.researcher !== 'Hamster'
					)
					setFilesData(noHamsterFiles)
				}
			} catch (err) {
				console.log('getAllFiles func error')
			}

			setIsLoader(false)
		}
	}

	const exitProcessorModal = async modalStatus => {
		const start = new Date(startDate)
		const end = new Date(endDate)
		const result = []
		const formatDate = date => {
			const year = date.getFullYear()
			const month = String(date.getMonth() + 1).padStart(2, '0')
			const day = String(date.getDate()).padStart(2, '0')
			return `${year}_${month}_${day}`
		}

		let currentDate = start
		while (currentDate <= end) {
			result.push(formatDate(currentDate))
			currentDate.setDate(currentDate.getDate() + 1)
		}

		try {
			const files = await axios.post(`${BASE_URL}/getAllUploadFiles`, {
				targetDate: result,
			})

			if (files) {
				const noHamsterFiles = files.data.filter(
					file => file.researcher !== 'Hamster'
				)
				setFilesData(noHamsterFiles)
			}
		} catch (err) {
			console.log('getAllFiles func error')
		}

		if (!modalStatus) {
			setModal(modalStatus)
		}
	}

	return (
		<div className={style.processor}>
			<Wellcome userName={userName} />

			<p className={style.text}>{TEXT.researcher_check_date}</p>

			<form onSubmit={checkFiles} className={style.row}>
				<span className={style.row__part}>
					<p>{TEXT.from}</p>

					<Input
						inputType={'date'}
						inputValue={startDate}
						setInputValue={setStartDate}
						maxValue={endDate}
					/>
				</span>

				<span className={style.row__part}>
					<p>{TEXT.to}</p>

					<Input
						inputType={'date'}
						inputValue={endDate}
						setInputValue={setEndDate}
						minValue={startDate}
					/>
				</span>

				<Btn type='submit' text={TEXT.check} />
			</form>

			{filesData && (
				<p className={style.text}>
					{TEXT.researcher_check_totally_uploaded} - {filesData.length}
				</p>
			)}

			<table>
				<thead>
					<tr>
						{headers.map((header, headerIndex) => (
							<th key={headerIndex} className={style.th}>
								{header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{filesData.map((fileData, fileDataIndex) => (
						<tr key={fileDataIndex}>
							{/* Date */}
							{fileData.post_date ? (
								<td title={fileData.post_date} className={style.td}>
									{fileData.post_date}
								</td>
							) : (
								<td className={style.td}></td>
							)}
							{/* Name */}
							{fileData.file_name ? (
								<td title={fileData.file_name} className={style.td}>
									{fileData.file_name}
								</td>
							) : (
								<td className={style.td}></td>
							)}
							{/* Resource */}
							{fileData.resource ? (
								<td title={fileData.resource} className={style.td}>
									{fileData.resource}
								</td>
							) : (
								<td className={style.td}></td>
							)}
							{/* UpDownBtn */}
							{!fileData.is_filtered ? (
								!fileData.is_ignored ? (
									<td
										title={
											fileData.is_uploaded
												? TEXT.processor_table_icon_up_down_load_title_ready
												: TEXT.processor_table_icon_up_down_load_title_notReady
										}
										className={style.td}
									>
										<UpDownBtn
											type={'processor'}
											data={fileData}
											handle={() => handleUpDownload(fileData)}
										/>
									</td>
								) : (
									<td
										title={TEXT.processor_table_icon_ignore_title}
										className={style.td}
									>
										<IgnoreIcon />
									</td>
								)
							) : (
								<td
									title={TEXT.processor_table_icon_done_title}
									className={style.td}
								>
									<DoneIcon />
								</td>
							)}
							{/* Researcher */}
							{fileData.researcher ? (
								<td title={fileData.researcher} className={style.td}>
									{fileData.researcher}
								</td>
							) : (
								<td className={style.td}></td>
							)}
							{/* Size */}
							{fileData.file_size ? (
								<td title={fileData.file_size} className={style.td}>
									{fileData.file_size}
								</td>
							) : (
								<td className={style.td}></td>
							)}
							{/* Comment */}
							{fileData.researcher_comment ? (
								<td title={fileData.researcher_comment} className={style.td}>
									{fileData.researcher_comment}
								</td>
							) : fileData.processor_comment ? (
								<td title={fileData.processor_comment} className={style.td}>
									{fileData.processor_comment}
								</td>
							) : (
								<td className={style.td}></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			<p className={style.errorText}>{errorMessage}</p>

			{modal && (
				<ProcessorModal
					data={fileData}
					exitHandle={exitProcessorModal}
					userName={userName}
				/>
			)}

			{filesData.length > 20 && <ScrollUpBtn />}

			{isLoader && <Loader />}
			{alertText.length > 0 && (
				<CustomAlert message={alertText} onClose={setAlertText} />
			)}
		</div>
	)
}
