import React, { startTransition, useContext, useEffect, useState } from 'react'
import style from './AddAndDeleteOption.module.scss'
import { DeleteBtn } from '../../c_ui/DeleteBtn'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import { useNavigate } from 'react-router-dom'
import { UserAdd } from '../UserAdd'
import { Loader } from '../Loader'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const AddAndDeleteOption = ({ userName }) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [users, setUsers] = useState([])
	const [isLoader, setIsLoader] = useState(false)

	useEffect(() => {
		fetchUsers()
	}, [])

	const fetchUsers = async () => {
		try {
			setIsLoader(true)
			const users = await axios.post(`${BASE_URL}/getAllUsers`)

			setUsers(users.data)
		} catch (error) {
			console.log('fetchUsers func error', error)
		} finally {
			setIsLoader(false)
		}
	}

	const handlerDeleteUser = async deleteUserId => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}

		try {
			setIsLoader(true)
			await axios.post(`${BASE_URL}/deleteUser`, {
				userId: deleteUserId,
			})

			await fetchUsers()
		} catch (error) {
			console.log('deleteUser error', error)
		} finally {
			setIsLoader(false)
		}
	}

	return (
		<div className={style.add_del}>
			<table>
				<thead>
					<tr>
						<th className={style.th}>{TEXT.inspector_table_users}</th>
						<th className={style.th}>{TEXT.inspector_table_statuces}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className={style.td}>Hamster</td>
						<td className={style.td}>{TEXT.researcher}</td>
					</tr>

					{users.length > 0 &&
						users.map((user, i) => (
							<tr key={i}>
								<td className={style.td}>
									<span className={style.td__wrapper}>
										{user.name}

										{user.name !== userName && (
											<DeleteBtn handle={handlerDeleteUser} value={user._id} />
										)}
									</span>
								</td>
								<td>{TEXT[user.role]}</td>
							</tr>
						))}
				</tbody>
			</table>

			<UserAdd handle={fetchUsers} />

			{isLoader && <Loader />}
		</div>
	)
}
