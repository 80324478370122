// import axios from 'axios'

function autorizationAnalizer() {
	if (sessionStorage.length) {
		const storedTime = Number(sessionStorage.getItem('time'))
		const currentTime = new Date().getTime()

		if (currentTime - storedTime > 3600000 * 6) {
			sessionStorage.clear()
			return false
		} else {
			return true
		}
	}
}

export default autorizationAnalizer
