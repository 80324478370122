import React, { startTransition, useContext, useEffect, useState } from 'react'
import style from './Researcher.module.scss'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Wellcome } from '../../components/Wellcome'
import { Btn } from '../../c_ui/Btn'
import { AddOption } from '../../components/AddOption'
import { getTranslation, Language } from '../../language'
import { CheckOption } from '../../components/CheckOption'
import autorizationAnalizer from '../../service/functions/autorizationAnalizer'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const Researcher = () => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const navigate = useNavigate()
	const [userName, setUserName] = useState('')
	const [choice, setChoice] = useState(false)
	const [addOption, setAddOption] = useState(false)
	const [checkOption, setCheckOption] = useState(false)

	useEffect(() => {
		async function fetchData() {
			const storedUserId = sessionStorage.getItem('id')

			try {
				const user = await axios.post(`${BASE_URL}/getUserById`, {
					userId: storedUserId,
				})

				if (user) {
					setUserName(user.data.name)
				}
			} catch (err) {
				console.log('fetchData func error')
				startTransition(() => {
					navigate('/')
				})
			}
		}

		fetchData()
	}, [])

	const handlerAdd = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}

		if (addOption) {
			return
		}

		if (checkOption) {
			setCheckOption(false)
		}

		if (!choice) {
			setChoice(true)
		}

		setAddOption(true)
	}

	const handlerCheck = () => {
		const autorizationData = autorizationAnalizer()
		if (!autorizationData) {
			startTransition(() => {
				navigate('/')
			})
		}

		if (checkOption) {
			return
		}

		if (addOption) {
			setAddOption(false)
		}

		if (!choice) {
			setChoice(true)
		}

		setCheckOption(true)
	}

	return (
		<div className={style.researcher}>
			<Wellcome userName={userName} />

			<div className={style.buttons}>
				<Btn
					text={TEXT.researcher_btn_add}
					handle={handlerAdd}
					isActive={addOption}
				/>
				<Btn
					text={TEXT.researcher_btn_check}
					handle={handlerCheck}
					isActive={checkOption}
				/>
			</div>

			{addOption && <AddOption userName={userName} />}
			{checkOption && <CheckOption userName={userName} />}

			{!choice && <p className={style.choice}>{TEXT.choice}</p>}
		</div>
	)
}
