import React, { useContext, useState } from 'react';
import { getTranslation, Language } from '../../language'
import { ExitBtn } from '../../c_ui/ExitBtn'
import style from './Wellcome.module.scss'
import { UserInfoModal } from '../UserInfoModal'

export const Wellcome = ({ userName }) => {
  const lang = useContext(Language)
  const TEXT = getTranslation(lang)
	const [modal, setModal] = useState(false)

	const handleModal = () => {
		document.body.style.overflow = 'hidden'
		setModal(true)
	}

	return (
		<span className={style.wellcome}>
			<span className={style.text}>
				{TEXT.welcome}
				<p className={style.name} onClick={handleModal}>
					{userName}
				</p>
				!
			</span>
			<ExitBtn />

			{modal && <UserInfoModal exitHandle={() => setModal(false)} />}
		</span>
	)
}
