import React, { useContext, useState } from 'react'
import axios from 'axios'
import { getTranslation, Language } from '../../language'
import { ExitBtn } from '../../c_ui/ExitBtn'
import style from './InspectorModal.module.scss'
import { DownloadBtn } from '../../c_ui/DownloadBtn'
import { ProcessorIcon } from '../../c_ui/ProcessorIcon'
import { ResearcherIcon } from '../../c_ui/ResearcherIcon'
import { UploadProgressModal } from '../UploadProgressModal'
import { IgnoreIcon } from '../../c_ui/IgnoreIcon'
import { DeleteBtn } from '../../c_ui/DeleteBtn'
import { Loader } from '../Loader'

const BASE_URL = process.env.REACT_APP_BASE_URL

const filesType = {
	filtered: 'filtered',
	unfiltered: 'unfiltered',
	ignored: 'ignored',
}

export const InspectorModal = ({
	data = [],
	exitHandle,
	inspectoredFilesType,
}) => {
	const lang = useContext(Language)
	const TEXT = getTranslation(lang)
	const [usedIds, setUsedIds] = useState([])
	const [progress, setProgress] = useState([])
	const [isProgressing, setIsProgressing] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isDeletedArr, setIsdeletedArr] = useState([])

	const filteredHeaders = [
		TEXT.inspector_modal_name,
		TEXT.inspector_modal_size,
		<ProcessorIcon />,
		TEXT.inspector_modal_comment,
		TEXT.inspector_modal_rows,
		<DownloadBtn type={'head'} />,
	]

	const unfilteredHeaders = [
		TEXT.inspector_modal_name,
		TEXT.inspector_modal_size,
		<ResearcherIcon />,
		TEXT.inspector_modal_comment,
		<DownloadBtn type={'head'} />,
		<DeleteBtn type={'head'} />,
	]

	const ignoredHeaders = [
		TEXT.inspector_modal_name,
		TEXT.inspector_modal_size,
		<ResearcherIcon />,
		TEXT.inspector_modal_comment,
		<ProcessorIcon />,
		TEXT.inspector_modal_comment,
		<DownloadBtn type={'head'} />,
		<DeleteBtn type={'head'} />,
	]

	const handleDownload = async currentData => {
		setIsProgressing(true)

		const isFilteredStatus = inspectoredFilesType === filesType.filtered
		const isUnfilteredOreIgnoredStatus =
			inspectoredFilesType === filesType.unfiltered ||
			inspectoredFilesType === filesType.ignored

		try {
			// Створення XMLHttpRequest для контролю прогресу завантаження
			const xhr = new XMLHttpRequest()

			// filtered files
			if (isFilteredStatus) {
				xhr.open(
					'POST',
					`${BASE_URL}/downloadFile/${currentData.post_date}/${currentData.resource}/filtered/${currentData.filtered_file_name}`,
					true
				)
			}

			// original ore ignored files
			if (isUnfilteredOreIgnoredStatus) {
				xhr.open(
					'POST',
					`${BASE_URL}/downloadFile/${currentData.post_date}/${currentData.resource}/original/${currentData.file_name}`,
					true
				)
			}

			xhr.responseType = 'blob'

			xhr.onprogress = event => {
				if (event.lengthComputable) {
					const progress = Math.round((event.loaded / event.total) * 100)

					// filtered files
					if (isFilteredStatus) {
						setProgress([
							{ fileName: currentData.filtered_file_name, progress: progress },
						]) // Оновлюємо прогрес
					}
					if (isUnfilteredOreIgnoredStatus) {
						setProgress([
							{ fileName: currentData.file_name, progress: progress },
						]) // Оновлюємо прогрес
					}
				}
			}

			xhr.onload = () => {
				if (xhr.status === 200) {
					const url = window.URL.createObjectURL(xhr.response)
					const link = document.createElement('a')
					link.href = url

					// запис імені файлу
					// filtered files
					if (isFilteredStatus) {
						link.setAttribute('download', currentData.filtered_file_name)
					}
					if (isUnfilteredOreIgnoredStatus) {
						link.setAttribute('download', currentData.file_name)
					}

					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)

					setUsedIds(prevIds => [...prevIds, currentData._id])
					setIsProgressing(false)
				}
			}

			xhr.onerror = err => {
				console.error('Download failed:', err)
				setIsProgressing(false)
			}

			xhr.send()

			// filtered files
			// console.log(currentData)
			if (isFilteredStatus && !currentData.is_inspector_take_filtered) {
				await axios.post(`${BASE_URL}/fileTake`, {
					id: currentData._id,
					role: 'inspector_filtered',
				})
			}
			if (
				isUnfilteredOreIgnoredStatus &&
				!currentData.is_inspector_take_original
			) {
				await axios.post(`${BASE_URL}/fileTake`, {
					id: currentData._id,
					role: 'inspector_original',
				})
			}
		} catch (err) {
			console.log(err)
		}
	}

	const handleDeleteFile = async deletedFile => {
		setIsLoading(true)

		try {
			setIsdeletedArr(prev => [...prev, deletedFile.file_name])
			await axios.post(`${BASE_URL}/deleteFile`, deletedFile)
			setTimeout(() => {
				setIsLoading(false)
			}, 1000)
		} catch (error) {
			console.log('deleteFile error', error)
		}
	}

	return (
		<div className={style.area}>
			<div className={style.modal}>
				<span className={style.exit}>
					<ExitBtn exitPropHandle={exitHandle} />
				</span>

				<div className={style.tableContainer}>
					<table>
						<thead>
							<tr>
								{inspectoredFilesType === filesType.filtered &&
									filteredHeaders.map((header, i) => (
										<th key={i} className={style.th}>
											{header}
										</th>
									))}
								{inspectoredFilesType === filesType.unfiltered &&
									unfilteredHeaders.map((header, i) => (
										<th key={i} className={style.th}>
											{header}
										</th>
									))}
								{inspectoredFilesType === filesType.ignored &&
									ignoredHeaders.map((header, i) => (
										<th key={i} className={style.th}>
											{header}
										</th>
									))}
							</tr>
						</thead>

						<tbody>
							{data.map((file, i) => (
								<tr key={i}>
									{/* Name */}
									{file.is_filtered &&
									inspectoredFilesType === filesType.filtered ? (
										<td title={file.filtered_file_name} className={style.td}>
											{file.filtered_file_name}
										</td>
									) : (
										<td title={file.file_name} className={style.td}>
											{file.file_name}
										</td>
									)}

									{/* Size */}
									<td title={file.file_size} className={style.td}>
										{file.file_size}
									</td>

									{/* Researcher */}
									{(inspectoredFilesType === filesType.unfiltered ||
										inspectoredFilesType === filesType.ignored) &&
										file.researcher && (
											<td className={style.td}>{file.researcher}</td>
										)}

									{/* Researcher comment */}
									{inspectoredFilesType === filesType.unfiltered ? (
										file.researcher_comment ? (
											<td className={style.td}>{file.researcher_comment}</td>
										) : (
											<td className={style.td}></td>
										)
									) : (
										inspectoredFilesType === filesType.ignored && (
											<td className={style.td}>{file.researcher_comment}</td>
										)
									)}

									{/* Processor */}
									{(inspectoredFilesType === filesType.filtered ||
										inspectoredFilesType === filesType.ignored) &&
										file.processor && (
											<td className={style.td}>{file.processor}</td>
										)}

									{/* Processor comment */}
									{inspectoredFilesType === filesType.filtered ? (
										file.processor_comment ? (
											<td className={style.td}>{file.processor_comment}</td>
										) : (
											<td className={style.td}></td>
										)
									) : (
										inspectoredFilesType === filesType.ignored && (
											<td className={style.td}>{file.ignore_comment}</td>
										)
									)}

									{/* Filtered rows */}
									{file.processed_rows && (
										<td className={style.td}>{file.processed_rows}</td>
									)}

									{/* Download */}
									<td title={'Download'} className={style.td}>
										{file.is_deleted ||
										isDeletedArr.includes(file.file_name) ? (
											<IgnoreIcon />
										) : file.is_uploaded ? (
											<DownloadBtn
												type={
													file.is_inspector_take_original ||
													file.is_inspector_take_filtered ||
													usedIds.includes(file._id)
														? 'downloaded'
														: ''
												}
												handle={() => handleDownload(file)}
											/>
										) : (
											<DownloadBtn type={'notUploaded'} />
										)}
									</td>

									{/* Delete */}
									{inspectoredFilesType !== filesType.filtered && file.researcher === 'Hamster' ? (
										<td title={'Delete'} className={style.td}>
											<DeleteBtn value={file} handle={handleDeleteFile} />
										</td>
									) : (<td></td>)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{isProgressing && <UploadProgressModal progress={progress} />}

			{isLoading && <Loader />}
		</div>
	)
}
